/*
 * APaaS详情页-产品实例
 * @Author: 翟晓佳
 * @Date: 2023-07-Fr 09:06:23
 * @Last Modified by:   翟晓佳
 * @Last Modified time: 2023-07-Fr 09:06:23
 */

import React from 'react';
import { Tabs, Button, Tag, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styles from './index.module.less';

const { TabPane } = Tabs;

function ApaaSInstanceType(props) {
  const { proList = [], goPurchase, hiddenBuyBtn, title } = props;

  const renderProCard = (proItem, instanceItem, index) => {
    const state = { classValue: instanceItem.value, productId: proItem.id };
    const { mainProductSpecList = [] } = proItem;
    return (
      <div
        key={proItem.id}
        className={styles.item}
        style={{
          borderTop: index < 3 ? '1px solid #f2f2f2' : '',
          borderLeft: index % 3 === 0 ? '1px solid #f2f2f2' : '',
        }}
      >
        <div className={styles.imgAndBtn}>
          <img className={styles.img} src={proItem.thumbnail} alt="apaas产品" />
          {!hiddenBuyBtn && (
            <Button
              type="primary"
              className={styles.buyBtn}
              size="middle"
              onClick={(e) => {
                goPurchase(e, state);
              }}
            >
              立即购买
            </Button>
          )}
        </div>

        <div className={styles.productName}>{proItem.productName}</div>
        <p className={styles.detail}><Tooltip title={proItem.description} placement="rightTop">{proItem.description}</Tooltip></p>
        <div className={styles.proTags}>
          {mainProductSpecList.map((v, i) => {
            return (
              <Tag key={i} className={styles.proTag}>
                {v.specName}：{v.value}{v.unitName}
              </Tag>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.instanceType}>
      <div className={styles.title}>{title}</div>
      <Tabs defaultActiveKey="0" animated>
        {proList.map((v, i) => {
          const {displayText,products=[]} = v;
          return (
            <TabPane tab={displayText} key={`${i}`}>
              <div
                className={`${styles.itemWrap} ${
                  products.length > 3 ? styles.noCenterFlow : null
                }`}
              >
                {products.slice(0,6).map((proItem, index) =>
                  renderProCard(proItem, v, index)
                )}
              </div>
              {products.length > 6 && (
                <span className={styles.lookMore} onClick={goPurchase}>
                  查看更多
                  <ArrowRightOutlined style={{ marginLeft: 7 }} />
                </span>
              )}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}

export default ApaaSInstanceType;

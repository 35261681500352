import React from 'react';
import Head from 'next/head';
import { observer, inject } from 'mobx-react';
import { Modal } from 'antd';

import { getProList } from '@src/service';

import NormalLayout from '@src/layouts/NormalLayout';
import InstanceType from '@src/pageComponents/V2APaaSIndex/InstanceType';
import NoAccess from '@src/components/NoAccess';
import Advantage from '@src/components/Advantage';
import Banner from '@src/pageComponents/V2APaaSIndex/Banner';
import APaaSFramework from '@src/pageComponents/V2APaaSIndex/APaaSFramework';
import CustomerCase from '@src/pageComponents/V2APaaSIndex/CustomerCase';

import { PRODUCT_TYPE } from '@src/const';

import styles from './v2apaas.module.less';

const advantageList = [
  {
    id: 1,
    title: '丰富的业务模型',
    text: '从平台多年工业信息化服务经验，提炼丰富的行业业务模型，共享于生态',
    icon: '/images/apaas-advantage-1.png',
  },
  {
    id: 2,
    title: '强大的开发能力',
    text: '丰富的扩展能力组件，为行业生态开发者提供强大的开发能力',
    icon: '/images/apaas-advantage-2.png',
  },
  {
    id: 3,
    title: '能力开放',
    text: '开发者可以通过能力开放平台，开放自己的能力',
    icon: '/images/apaas-advantage-3.png',
  },
  {
    id: 4,
    title: '流量窗口',
    text: '突破平台局限，打破流量壁垒，为开发者提供高效的APP分发平台',
    icon: '/images/apaas-advantage-4.png',
  },
  {
    id: 5,
    title: '低成本高效率',
    text: '更低的服务使用成本，更低的开发技术门槛，助力开发者效率提升',
    icon: '/images/apaas-advantage-5.png',
  },
  {
    id: 6,
    title: '安全可控',
    text: '多租户数据隔离，全方位保障客户系统安全和数据安全',
    icon: '/images/apaas-advantage-6.png',
  },
];

const TabsList = [
  {
    label: '产品规格',
    key: 'appas-instanceType',
  },
  {
    label: '产品优势',
    key: 'apaas-advantage',
  },
  {
    label: '产品架构',
    key: 'apaas-apaasFramework',
  },
  {
    label: '客户案例',
    key: 'apaas-customerCase',
  },
];

@inject('appGlobalModel')
@inject('modelAPaaS')
@NormalLayout
@observer
class APaaSPage extends React.Component {
  constructor(props) {
    super(props);
    this.globalStore = props.appGlobalModel;
    this.store = props.modelAPaaS;

    this.state = {
      visible: false,
      activeTab: 'appas-instanceType',
      fixed: false
    };
  }

  componentDidMount() {
    // 服务端渲染兼容
    if (typeof window !== 'undefined') {
      document.body.style.height='auto'
      history.scrollRestoration = 'manual'
      const { getProList } = this.store;
      // 在浏览器端再调用一次产品初始化接口，因为产品数据和用户有关
      getProList({
        productType: PRODUCT_TYPE.V2APaaS,
      });
      window.addEventListener('scroll', () => {
        const tabBoxOffsetTop = document.getElementById('tabBox').getBoundingClientRect().top
        if (tabBoxOffsetTop < 60) {
          this.setState({ fixed: true })
        } else {
          this.setState({ fixed: false })
        }
      })
    }
  }

  toggleModal = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };

  goPurchase = (e, { productId = '', classValue = '' } = {}) => {
    const {
      currentInfo: { certificationStatus },
    } = this.globalStore;

    // 如果已经认证过或者用户没有登录 都跳到商品购买页面（未登录会在这个页面触发去登录）
    if (
      certificationStatus === 2 ||
      certificationStatus === 3 ||
      certificationStatus === undefined
    ) {
      window.location.href = `/product/select-purchase-info?productType=${PRODUCT_TYPE.V2APaaS}&productId=${productId}&classValue=${classValue}&from=lzy`;
    } else {
      this.toggleModal();
    }
  };

  handleSelectedTab = (key) => {
    const offsetTop = document.documentElement.scrollTop + document.getElementById(key).getBoundingClientRect().top - 126
    this.setState({ activeTab: key }, () => {
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth"
      })
    })
  }

  renderTabs = () => {
    const { fixed, activeTab } = this.state
    return (
      <div id='tabBox' style={{ height: '67px' }}>
        <div className={fixed ? styles.tabWrapFixed : styles.tabWrap}>
          <div className={styles.tabContent}>
            {TabsList.map((item) => {
              return (
                <span
                  className={activeTab === item.key ? styles.tabItemActive : styles.tabItem}
                  title={item.label}
                  key={item.key}
                  onClick={() => this.handleSelectedTab(item.key)}
                >
                  {item.label}
                </span>)
            })}
          </div>
        </div>
      </div>

    );
  };

  render() {
    const { APaaSProList } = this.store;
    const {
      currentInfo: { certificationStatus },
    } = this.globalStore;
    const { visible } = this.state;
    const hiddenBuyBtn = true;

    return (
      <div className={styles['page-aPaaS']}>
        <Head>
          <title></title>
          <meta
            name="keywords"
            content="APaaS,云服务，云平台，工业云，工业云服务，弹性云服务，supOS，实例，工业操作系统，云计算"
          ></meta>
          <meta
            name="description"
            content="云工业操作系统服务APaaS（application Platform as a Service，简称APaaS）是一种面向企业用户的基于云计算平台的即开即用、安全可靠、弹性伸缩、易于维护的在线supOS工业操作系统服务。通过云工业操作系统服务APaaS，企业用户可实现分钟级别的supOS工业操作系统服务实例开通和弹性扩展。云工业操作系统服务ESS 提供工业数据湖备份恢复、工业APPs性能监控、工业操作系统监控、快速扩容等全套解决方案，为企业简化supOS工业操作系统的运维工作，使其能更加专注于业务发展和功能开发。"
          ></meta>
        </Head>
        <Banner goPurchase={this.goPurchase} />
        {this.renderTabs()}
        <div id="appas-instanceType">
          <InstanceType
            goPurchase={this.goPurchase}
            proList={APaaSProList}
            title="产品规格"
            hiddenBuyBtn={false}
          />
        </div>
        <div id="apaas-advantage">
          <Advantage title="产品优势" list={advantageList} titleFontSize='36px' showSubTitle={true} />
        </div>
        {/* <BuildEcological /> */}
        <div id="apaas-apaasFramework">
          <APaaSFramework />
        </div>
        <div id="apaas-customerCase">
          <CustomerCase />
        </div>

        <Modal
          width="650px"
          onCancel={this.toggleModal}
          footer={null}
          title="提示"
          visible={visible}
        >
          <NoAccess
            handleConfirm={this.toggleModal}
            certificationStatus={certificationStatus}
          />
        </Modal>
      </div>
    );
  }
}

export async function getStaticProps() {
  const { success, data } = await getProList({
    productType: PRODUCT_TYPE.V2APaaS,
  });
  // Pass data to the page via props
  return {
    props: {
      staticProps: {
        appGlobalModel: {},
        modelAPaaS: { APaaSProList: data },
      },
      revalidate: 10,
    },
  };
}

export default APaaSPage;

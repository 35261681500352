import React from 'react';
import { Carousel } from 'antd';
import styles from './index.module.less';

const CustomerCase = () => {
  return (
    <div className={styles.customerCase}>
      <div className={styles.header}>
        <h3>
          <span id="customerCase">客户案例</span>
        </h3>
        <p>以aPaaS+ SaaS服务模式，引领行业数字化转型的典型案例</p>
      </div>
      <div className={styles.content}>
        <Carousel dots={true} autoplay={false}>
          <div className={styles['carousel-item']}>
            <div className={styles.left}>
              <div className={styles.top}>
                <h4>某汽车座椅公司智能制造平台</h4>
                <div className={styles.label}>方案</div>
                <div
                  className={styles['describe-item']}
                  describe="通过提供数据采集与设备管理，实时监控产区内部生产情况。"
                ></div>
                <div
                  className={styles['describe-item']}
                  describe="通过信息化系统，规范化管理企业资源与生产资料。"
                ></div>
                <div
                  className={styles['describe-item']}
                  describe="通过信息化产品，增加产成品销售手段与宣传方式，提高产品附加值与企业形象。"
                ></div>
                <div className={styles.label} style={{ marginTop: '40px' }}>
                  价值
                </div>
                <div
                  className={styles['describe-item']}
                  describe="设备的全生命周期管理，全面提升了设备的运行可靠性和经济性。"
                ></div>
                <div
                  className={styles['describe-item']}
                  describe="企业内部生产设备实时采集与监控，实现管理透明化、可视化。"
                ></div>
                <div
                  className={styles['describe-item']}
                  describe="通过 3D 产品手册，增加产品营销方案，提升了产品销量。"
                ></div>
              </div>
              <div className={styles.bottom}>
                <img src="/images/icon15.png" alt="" />
              </div>
            </div>
            <div className={styles.right}>
              <img src="/images/ex1.png" alt="" />
            </div>
          </div>
          <div className={styles['carousel-item']}>
            <div className={styles.left}>
              <div className={styles.top}>
                <h4>某驱动轴企业数字化工厂</h4>
                <div className={styles.label}>方案</div>
                <div
                  className={styles['describe-item']}
                  describe="通过设备联网和实时数据采集，结合设备管理系统，实现了工厂设备和生产数据实时化、透明化，无纸化；数据可视化报表在可视化大屏实时统计显示，设备保养维修录有迹可循，缩短了设备故障响应时间。"
                ></div>
                <div
                  className={styles['describe-item']}
                  describe="通过量检具管理系统，实现从量检具台账、借用记录到校验全流程数字化管理，自动为检具打印合格标签实时提示检具校准时间，避免漏校。"
                ></div>
                <div className={styles.label} style={{ marginTop: '40px' }}>
                  价值
                </div>
                <div
                  className={styles['describe-item']}
                  describe="设备维修保养规范化，OEE 提升 18%。"
                ></div>
                <div
                  className={styles['describe-item']}
                  describe="准确监控及定位产量波动原因，确保产量持续平稳。"
                ></div>
                <div
                  className={styles['describe-item']}
                  describe="建立完成生产计划达成，订单准时交付预警机制。订单交付按时率提升 36%。"
                ></div>
                <div
                  className={styles['describe-item']}
                  describe="仪器量检具校验速度提升 25%，准时率提升至 98%，校验率 100%。"
                ></div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.pages}></div>
                <img src="/images/icon15.png" alt="" />
              </div>
            </div>
            <div className={styles.right}>
              <div>
                <img src="/images/ex2.png" alt="" />
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default CustomerCase;

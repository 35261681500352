/*
 * @Author: luoxia
 * @Date: 2022-07-18 14:53:06
 * @LastEditors: luoxia
 * @LastEditTime: 2022-08-11 11:33:12
 */
import React from 'react';

import styles from './index.module.less';

const Advantage = ({ title = '', list = [], titleFontSize = '28px', showSubTitle = false }) => {
  return (
    <section className={styles['advantage']}>
      <div className={styles.header}>
        <div style={{fontSize:titleFontSize}}>{title}</div>
        {showSubTitle&&<p>为APP开发者提供多样化的、广泛的工业APP开发能力引擎，为企业客户提供一站式行业解决方案</p>}
        </div>
      
      <div className={styles.list}>
        {list.map((i) => {
          return (
            <div key={i.id} className={styles.item}>
              <img src={i.icon} alt="logo" />
              <div className={styles['triangle-box']}>
                <div className={styles.triangle}></div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.title}>{i.title}</div>
                <div className={styles.divide}></div>
                <p>{i.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Advantage;

/*
 * @Author: luoxia
 * @Date: 2022-07-18 14:41:33
 * @LastEditors: luoxia
 * @LastEditTime: 2022-07-18 14:49:10
 */
import React from 'react';

import styles from './index.module.less';

const APaaSFramework = () => {
  return (
    <section className={styles['apaas-framework']}>
      <div className={styles.header}>APaaS能力架构图</div>
      <img src="/images/apaas-framework.png" alt="logo" />
    </section>
  );
};

export default APaaSFramework;

/*
 * @Author: luoxia
 * @Date: 2022-07-18 14:04:30
 * @LastEditors: luoxia
 * @LastEditTime: 2022-07-20 18:55:37
 */
import React from 'react';

import styles from './index.module.less';

const ecologicalList = [
  {
    id: 1,
    text: '开箱即用',
    icon: '/images/apaas-easy.png',
  },
  {
    id: 2,
    text: '按需付费',
    icon: '/images/apaas-free.png',
  },
  {
    id: 3,
    text: '弹性伸缩',
    icon: '/images/apaas-elasticity.png',
  },
  {
    id: 4,
    text: '零运维',
    icon: '/images/apaas-op.png',
  },
];

const BuildEcological = () => {
  return (
    <section className={styles['build-ecological']}>
      <div className={styles.center}>
        <div className={styles.header}>构建云端工业互联网合作生态</div>
        <p className={styles.desc}>
          为APP开发者提供多样化的、广泛的工业APP开发能力引擎，为企业客户提供一站式行业解决方案
        </p>
        <div className={styles.list}>
          {ecologicalList.map((i) => {
            return (
              <div key={i.id} className={styles.item}>
                <img src={i.icon} alt="logo" />
                <div>{i.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BuildEcological;

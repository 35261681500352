/*
 * @Author: luoxia
 * @Date: 2022-07-18 13:50:50
 * @LastEditors: 罗侠 luoxia@supos.com
 * @LastEditTime: 2022-12-21 10:54:13
 */
import React from 'react';
import Button from '../../../components/Button';

import styles from './index.module.less';

const Banner = ({ goPurchase }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.center}>
        <div className={styles.title}>行业云APaaS平台</div>
        <p className={styles.intro}>
          依托于平台多年工业信息化服务经验， 以及工业操作系统能力基座，
          我们推出了拥有核心技术的行业APaaS平台，
          通过助力生态合作伙伴，为行业提供丰富的工业APP开发能力和行业解决方案。
        </p>
        <div className={styles.btnWrap}>
          {/* <Button
            noBorder
            styles={{
              color: '#fff',
              fontWeight: 'normal',
              marginRight: '24px',
            }}
            radius="big"
            type="primary"
            width="200px"
            onClick={goPurchase}
          >
            立即购买
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;

